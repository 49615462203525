import { DropdownButton } from '@/components/Button/Dropdown';
import { useStorage } from '@/hooks/useStorage';
import { tierIcons } from '@/lib/shared/DealsData';
import { objectToQueryString } from '@/lib/utils/queryParams';
import { isNil } from '@/lib/utils/values';
import { Statistics } from '@/types/statistics';
import { PaidSection } from '@/views/dashboard/sections/PaidSection';
import { C2CAcquisitionSection } from '@/views/dashboard/sections/C2CAcquisitionSection';
import { C2CSalesSection } from '@/views/dashboard/sections/C2CSalesSection';
import { useRouter } from 'next/navigation';
import { useState } from 'react';
import { useDashboardUsersMutation } from '@/views/dashboard/useDashboardQuery';
import { DashboardViewSkeleton } from '@/views/dashboard/DashboardViewSkeleton';
import { DashboardRedirectProps } from '@/views/dashboard/types/DashboardRedirectProps';
import { useDealTierStore } from '@/stores/useDealTierStore';

interface DashboardViewV2Props {
    readonly data: Statistics;
}

export function DashboardView({ data }: DashboardViewV2Props) {
    const router = useRouter();
    const { setItem } = useStorage();
    const { getDealTier, setDealTier } = useDealTierStore();
    const { mutateAsync: useDashboardUsers, isPending } = useDashboardUsersMutation();

    const tiers = ['PAID', 'C2C'];
    const selectedTier = getDealTier();
    const [selectedDealTier, setSelectedDealTier] = useState<string>(
        isNil(selectedTier) || ['RENT', 'ALL'].includes(selectedTier) ? 'PAID' : selectedTier,
    );

    const handleRedirect = (props: DashboardRedirectProps) => {
        setDealTier(props.tier);
        setItem('deals-filters', objectToQueryString(props.filters));

        router.push('/deals');
    };

    const handleRedirectVehicles = (props: DashboardRedirectProps) => {
        setDealTier(props.tier);
        setItem('vehicles-filters', objectToQueryString(props.filters));

        router.push('/vehicles');
    };

    return (
        <div className="space-y-8 gap-8">
            <div className="flex items-center gap-8">
                <div className="flex gap-8 items-center pb-6">
                    <h1 className="font-semibold text-2xl">Panel principal</h1>
                    <DropdownButton
                        className="w-8/12 justify-between min-w-fit"
                        type={'button'}
                        color="light-gray"
                        variant="outline"
                        size="small"
                        label={`${tierIcons[selectedDealTier]} ${selectedDealTier}`}
                    >
                        <ul className="py-2 text-sm text-gray-700 dark:text-gray-200">
                            {tiers.map((tier) => (
                                <button
                                    key={tier}
                                    className="block px-4 py-2 w-full text-start hover:bg-gray-100"
                                    onClick={() => {
                                        if (selectedDealTier !== tier) useDashboardUsers({ tier });
                                        setSelectedDealTier(tier);
                                        setDealTier(tier);
                                    }}
                                >
                                    {`${tierIcons[tier]} ${tier}`}
                                </button>
                            ))}
                        </ul>
                    </DropdownButton>
                </div>
            </div>
            {isPending && <DashboardViewSkeleton title="Cargando..." />}
            {selectedDealTier === 'PAID' && !isPending && <PaidSection data={data} redirectToDeals={handleRedirect} />}
            {selectedDealTier === 'C2C' && !isPending && (
                <div className="flex w-full gap-8">
                    <div className="w-1/2">
                        <C2CAcquisitionSection data={data} redirectToVehicles={handleRedirectVehicles} />
                    </div>
                    <div className="w-1/2">
                        <C2CSalesSection data={data} redirectToDeals={handleRedirect} />
                    </div>
                </div>
            )}
        </div>
    );
}
