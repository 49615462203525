import { useRef, useState } from 'react';

import { useClickOutside } from '@/hooks/useClickOutside';
import { HiChevronDown } from 'react-icons/hi';
import { Button, ButtonProps } from './Button';

interface DropdownButtonProps extends ButtonProps {
    readonly label: string | React.ReactNode;
    readonly chevron?: boolean;
    readonly type?: 'submit' | 'button';
    readonly children?: React.ReactNode;
    readonly direction?: 'left' | 'right';
}

export function DropdownButton({ direction, chevron = true, ...props }: DropdownButtonProps) {
    const [isOpen, setIsOpen] = useState(false);
    const ref = useRef<HTMLInputElement>(null);

    useClickOutside(ref, () => {
        setIsOpen(false);
    });

    const handleToogleDropdown = () => setIsOpen(!isOpen);

    return (
        <div className="relative" ref={ref}>
            <Button {...props} onClick={handleToogleDropdown}>
                {props.label}
                {chevron && <HiChevronDown className=" h-5 w-5" />}
            </Button>

            {isOpen && (
                <div
                    className={`absolute ${
                        direction === 'left' ? 'left-0' : 'right-0'
                    } z-10 mt-2 w-fit min-w-[224px] divide-y divide-gray-100 rounded-lg bg-white shadow text-xs cursor-pointer`}
                    onClick={handleToogleDropdown}
                >
                    {props.children}
                </div>
            )}
        </div>
    );
}
